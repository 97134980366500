import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

export default function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <h2>Welcome to BlueAntWorks</h2>
      <p>
        BlueAntWorks specializes in the development and implementation of
        multimedia content for instructional purposes principally relating to
        intellectual property rights management and industrial development
        programs.
      </p>
      <p>
        BlueAntWorks operates through a team of on-demand experts across the
        range of software development, content creation and provision of
        training services. Login is required for access to client pages.

      </p>
                           Contact us: info@blueantworks.com
       <p></p>                        
      <div style={{ maxWidth: `300px`, margin: `1.45rem 0` }}>
        <Image />
      </div>
    </Layout>
  )
}
